
import { Options, Vue } from 'vue-class-component';

@Options({
	props: {
		operation: Object
	},
	computed: {
		customer()
		{
			if (this.operation != null && this.operation.client != null)
			{
				return `${this.operation.client.surname} ${this.operation.client.name} ${this.operation.client.email}`;
			}
		}
	}
})

export default class OperationsView extends Vue
{
}
