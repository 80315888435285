
import { defineComponent } from 'vue'
import OperationsView from '@/components/OperationsView.vue'


export default defineComponent({
	name: 'Home',
	components: {
		OperationsView
	}
});
