import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "field"
}
const _hoisted_2 = { class: "control" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isFetched)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("select", {
            multiple: "",
            id: "clientPickerSelect",
            "data-type": "tags",
            "data-placeholder": "Select one or more clients",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selection) = $event)),
            ref: "select"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clients, (item) => {
              return (_openBlock(), _createElementBlock("option", {
                key: item.id,
                value: item.id
              }, _toDisplayString(item.surname) + " " + _toDisplayString(item.name), 9, _hoisted_3))
            }), 128))
          ], 512), [
            [_vModelSelect, _ctx.selection]
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, " Client list is not yet fetched! Loading... "))
}