
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from 'vuex';

export default defineComponent({
	computed: {
		...mapGetters([
			"isAuthenticated",
			"isLoading",
			"getLoadingMessage"
		])
	},
	methods: {
		...mapMutations({
			logout: 'logout'
		})
	}
});
