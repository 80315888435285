
import { defineComponent } from "vue";
import { mapGetters } from 'vuex';
import Client from "../types/Client";
import BulmaTagsInput from "@creativebulma/bulma-tagsinput";
const axios = require("axios").default;

export default defineComponent({
	props: {
		selectedClient: Array
	},
	emits: [
		"update:selectedClient"
	],
	data()
	{
		return {
			clients: [] as Client[],
			selection: {} as any,
			isFetching: false,
			isFetched: false,

			selectElement: {} as any
		}
	},
	methods: {
		getClients(): void
		{
			this.isFetching = true;
			axios.get(process.env.VUE_APP_HOST + "/client/all")
				.then((response: any) => {
					this.isFetching = false;
					this.isFetched = true;

					this.clients = response.data;
				})
				.then(() => {
					this.selectElement = new BulmaTagsInput((this.$refs.select as any), {
						freeInput: true,
						caseSensitive: false
					});
				})
				.then(() => {
					this.getMessageBus.on("ClientPicker.clear", () => this.selectElement.flush());
				});
		}
	},
	watch: {
		selection(): void
		{
			this.$emit("update:selectedClient", this.selection);
		}
	},
	computed: {
		...mapGetters(["getMessageBus"])
	},
	mounted()
	{
		this.getClients()
	}
});
