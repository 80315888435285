import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
require("./assets/global.scss");
const eva = require("eva-icons");
import Keycloak from 'keycloak-js';
const axios = require("axios").default;
import mitt from "mitt";


const initOptions = {
	url: process.env.VUE_APP_IS_HOST,
	realm: process.env.VUE_APP_IS_REALM,
	clientId: process.env.VUE_APP_IS_CLIENT,
	onLoad: 'login-required' as const,
	redirectUri: process.env.VUE_APP_IS_REDIRECT
}

const keycloak = Keycloak(initOptions);
keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
	if (!auth) {
		window.location.reload();
	}
	else {
		window.location.hash = "";
	}

	createApp(App)
		.use(store)
		.use(router)
		.mount('#app');

	store.commit("setMessageBus", mitt());
	store.commit('setKeycloak', keycloak);
	axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;

	setInterval(() => {
		keycloak.updateToken(70).then((refreshed) =>
		{
			if (refreshed)
			{
				axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
				console.log('Token refreshed' + refreshed);
			}
			else
			{
				if (keycloak.tokenParsed != null && keycloak.tokenParsed.exp != null && keycloak.timeSkew != null)
				{
					console.log('Token not refreshed, valid for '
						+ Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
				}
			}
		})
		.catch(() =>
		{
			console.log('Failed to refresh token');
		});


	}, 60000)

})
.catch(() =>
{
	console.log("Authenticated Failed");
});
eva.replace();

