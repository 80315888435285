import { createStore } from 'vuex'

export default createStore({
	state: {
		// authentication
		keycloak: null,
		authenticated: false,
		// message bus
		mitt: null,
		// other
		loading: false,
		loadingMessage: ""
	},
	getters: {
		isAuthenticated(state): boolean
		{
			return state.authenticated;
		},
		getMessageBus(state)
		{
			return state.mitt;
		},
		isLoading(state)
		{
			return state.loading;
		},
		getLoadingMessage(state)
		{
			return state.loadingMessage;
		}
	},
	mutations: {
		// authentication
		setKeycloak(state, key)
		{
			state.keycloak = key;
			state.authenticated = key.authenticated;
		},
		logout(state)
		{
			// @ts-ignore
			console.log(state.keycloak.logout);
			// @ts-ignore
			state.keycloak.logout();
		},
		// message bus
		setMessageBus(state, mb)
		{
			state.mitt = mb;
		},
		// other
		setLoading(state, value)
		{
			state.loading = value;
		},
		setLoadingMessage(state, value)
		{
			state.loadingMessage = value;
		}
	},
	actions: {
	},
	modules: {
	}
})

