import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			isPublic: true
		}
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('@/views/About.vue')
	},
	{
		path: "/clients",
		name: "Clients",
		component: () => import("@/views/Clients.vue")
	},
	{
		path: "/op-new",
		name: "NewOperation",
		component: () => import("@/views/NewOperation.vue")
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes: routes
});

export default router
