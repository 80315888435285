
import { defineComponent } from 'vue';
import { mapState } from "vuex";
const axios = require('axios').default;

const quickview = require("../../node_modules/bulma-quickview/dist/js/bulma-quickview.min.js");
import BulmaTagsInput from "@creativebulma/bulma-tagsinput";

import OperationsViewQuickView from "@/components/OperationsViewQuickView.vue";
import ClientPicker from './ClientPicker.vue';

export default defineComponent({
	props: [],
	data()
	{
		let now = new Date();
		// Ugly solution for the first day of the month due to limitations of JS's Date
		let start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
		start.setDate(3);
		start.setMonth(now.getMonth() - 1);

		return {
			operations: [] as any[],
			operationFilter: ["DRAFT", "INVOICED", "PAID"],
			clientFilter: [] as string[],
			filterStart: start.toISOString().substr(0, 8)+"01",
			filterEnd: now.toISOString().substr(0, 10),
			selectedInvoice: { operations: [] },
			allSelected: "",
			isMerging: false,
			isSending: false,
		};
	},
	methods: {
		getAllOperations()
		{
			let endDate = new Date(this.filterEnd);
			endDate.setDate(endDate.getDate() + 1);
			let endDateStr = endDate.toISOString().substr(0, 10);
			axios.get(process.env.VUE_APP_HOST + "/documents", {params: {start: this.filterStart, end: endDateStr}})
				.then((response: any) =>
				{
					console.log(response);
					this.operations = response.data;
				})
				.then(() => quickview.attach())
				.catch(() => alert("Cannot fetch data from server"));
		},
		mergeOperations()
		{
			this.isMerging = true;
			axios.post(process.env.VUE_APP_HOST + "/documents/merge", this.selectedOperations)
			.then((response : any) =>
			{
				this.getAllOperations()
				this.isMerging = false;
				response;
			});
		},
		downloadPdf(id: string)
		{
			let url = process.env.VUE_APP_HOST + "/documents/pdf/" + id;
			axios.get(url, {
				method: "GET",
				responseType: "blob",
				headers: { Accept: "application/octet-stream" }
			})
				.then((response: any) =>
				{
					let blob = new Blob([response.data]);
					let file = window.URL.createObjectURL(blob);
					window.open(file, "_blank");
				});
		},
		sendOut(id: string)
		{
			axios.post(process.env.VUE_APP_HOST + "/documents/sendOut", [id])
				.then((response: any) =>
				{
					response;
					this.getAllOperations();
				});
		},
		sendOutSelected()
		{
			this.isSending = true;
			let ops = this.operations
				.filter((op : any) => this.selectedOperations.includes(op.id))
				.map((op : any) => op.id);
			axios.post(process.env.VUE_APP_HOST + "/documents/sendOut", ops)
				.then((response: any) =>
				{
					response;
					this.isSending = false;
					this.getAllOperations();
				})
				.catch(() => this.isSending = false);
		},
		updateState(id: string, state: string): void
		{
			axios.put(process.env.VUE_APP_HOST + "/documents/" + id + "/state", state, {headers: {"Content-Type": "application/json"}})
				.then((response: any) =>
				{
					response;
					this.getAllOperations();
				});
		},

		// ui related
		getTagClass(state: string): string
		{
			switch (state)
			{
				case "DRAFT": return "is-warning";
				case "INVOICED": return "is-danger";
				case "PAID": return "is-success";
				default: return "";
			}
		}
	},
	computed: {
		disableMergeFunction(): any
		{
			return this.operations.filter(op => op.selected == "true").length <= 1;
		},
		filteredOperations(): any
		{
			const defaultList = ["PAID", "INVOICED", "DRAFT"];
			let ops: any = this.operationFilter.length == 0 ?
				this.operations.filter((op: any) => defaultList.includes(op.state)) :
				this.operations.filter((op: any) => this.operationFilter.includes(op.state));
			return this.clientFilter.length == 0 ? ops : ops.filter((op: any) => this.clientFilter.includes(op.client.id));
		},
		selectedOperations()
		{
			let selected: any[] = [];
			this.operations.forEach((operation: any) =>
			{
				if (operation.selected == "true")
				{
					selected.push(operation.id);
				}
			});

			return selected;
		},
		...mapState(
		{
			ck: 'keycloak',
		})
	},
	watch: {
		filterStart()
		{
			this.getAllOperations();
		},
		filterEnd()
		{
			this.getAllOperations();
		},
		allSelected(newVal : string)
		{
			if (newVal == "true")
			{
				this.filteredOperations.forEach((op : any) => op.selected = "true");
			}
			else if (newVal == "false")
			{
				this.filteredOperations.forEach((op : any) => op.selected = "false");
			}
		}
	},
	mounted()
	{
		this.getAllOperations();
		quickview.attach();
		BulmaTagsInput.attach((this.$refs.statusFilter as any));
	},
	components: {
		OperationsViewQuickView,
		ClientPicker
	}
});
